import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from 'utils/localized-link';

import AccordionList from 'components/accordion-list';
import Button from '@youship/components/objects/button';

import './styles.scss';

const FaqsList = ({ lists }) => {
  const intl = useIntl();

  return (
    <div className="faqs-list">
      <div className="container">
        {Array.isArray(lists) && lists.map((list, index) => (
          <div
            key={index}
            className="faqs-list__faqs"
          >
            <div className="row">
              <div className="col col-12">
                <h2 className="faqs-list__title">
                  <FormattedMessage id={list.title} />
                </h2>
              </div>
              <div className="col col-12">
                <AccordionList
                  bordered
                  list={list.faqs}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="row">
          <div className="col col-12">
            <div className="faqs-list__contact">
              <h2>
                <FormattedMessage id="faqs.contact.title" />
              </h2>
              <Button
                classNames="faqs-list__button"
                context="primary"
                linkComponent={Link}
                linkProps={{ to: '/contacts' }}
                noBackground
                noPadding
                noShadow
                text={intl.formatMessage({ id: 'faqs.contact.link' })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FaqsList.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    faqs: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    }))
  }))
};

FaqsList.defaultProps = {
  lists: null
};

export default FaqsList;
